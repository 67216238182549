.standard-card {
  background-color: $grey-100;
  width: 343px;
  border-radius: 0.75rem;
  font-family: "Graphik";

  .container {
    padding: 1rem;
  }
}

.standard-card__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.standard-card--type-1 {
  @include media-breakpoint-down(xs-2) {
    width: 312px;
  }

  .standard-card__title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    color: $grey-60;
  }

  .standard-card__content {
    display: flex;
    margin-top: 0.5rem;
    align-items: stretch;

    .standard-card__content-image {
      margin-right: 1rem;
      align-self: flex-start;
      width: toRem(32);
      height: toRem(32);
      @include media-breakpoint-down(md) {
        width: toRem(28);
        height: toRem(28);
      }
      @include media-breakpoint-down(xs-1) {
        width: toRem(24);
        height: toRem(24);
      }
    }

    .standard-card__content-desc {
      .standard-card__content-desc-price {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 27px;
        color: $white; 
        overflow: hidden;
        white-space: nowrap;
      }

      .standard-card__content-desc-currency {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: $grey-20;
      }

      .standard-card__content-desc-token {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: $grey-60;
      }
    }

    .standard-card__content-graph {
      margin-left: auto;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .standard-card__content-percentage {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.25rem;

        .standard-card__content-arrow {
          width: auto;
          height: auto;
          max-width: 1rem;
          max-height: 1rem;
        }

        .standard-card__content-value {
          font-size: 1rem;
          font-weight: 400;
          line-height: 24px;
          color: $green;
        }
      }

      .standard-card__content-chart-wrapper {
        margin-top: auto;
        width: auto;
        height: auto;
        max-width: 96px;
        max-height: 28px;
        pointer-events: none;

        .standard-card__content-chart-render {
          position: relative;
          top: -2.5rem;
          right: -0.5rem;
        }
      }
    }
  }
}

.standard-card--type-2 {
  @include media-breakpoint-down(xs-2) {
    width: 312px;
  }

  .standard-card__title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    color: $grey-60;
  }

  .standard-card__content {
    display: flex;
    margin-top: 0.5rem;
    align-items: stretch;

    .standard-card__content-image {
      margin-right: 1rem;
      align-self: flex-start;
      width: toRem(32);
      height: toRem(32);
      @include media-breakpoint-down(md) {
        width: toRem(28);
        height: toRem(28);
      }
      @include media-breakpoint-down(xs-1) {
        width: toRem(24);
        height: toRem(24);
      }
    }

    .standard-card__content-desc {
      .standard-card__content-desc-price {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 27px;
        color: $white;
        overflow: hidden;
        white-space: nowrap;
      }

      .standard-card__content-desc-currency {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: $grey-20;
      }

      .standard-card__content-desc-token {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: $grey-60;
      }
    }

    .standard-card__content-graph {
      margin-left: auto;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .standard-card__content-date {
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 22px;
        color: $grey-40;
      }

      .standard-card__content-chart-wrapper {
        margin-top: auto;
        width: auto;
        height: auto;
        max-width: 96px;
        max-height: 28px;

        .standard-card__content-chart-render {
          position: relative;
          top: -2.5rem;
          right: -0.5rem;
        }
      }
    }
  }
}

.standard-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.standard-card--type-3-v1,
.standard-card--type-3-v2 {
  width: 346px;

  .container {
    padding: 1.5rem;
  }

  .standard-card__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .standard-card__content-graph {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .standard-card__content-image {
        width: toRem(44);
        height: toRem(44);
        @include media-breakpoint-down(md){      
          width: toRem(32);
          height: toRem(32);
        }
        @include media-breakpoint-down(xs-1){      
          width: toRem(28);
          height: toRem(28);
        }
      }

      .standard-card__content-chart-wrapper {
        width: auto;
        height: auto;
        max-width: 137px;
        max-height: 38px;

        .standard-card__content-chart-render {
          position: relative;
          top: -1.875rem;
          right: -0.625rem;
        }
      }
    }

    .standard-card__content-percentage {
      display: flex;
      gap: 0.25rem;
      margin-left: auto;

      .standard-card__content-value {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 27px;

        &.text-bullish {
          color: $green;
        }

        &.text-bearish {
          color: $red;
        }
      }
    }

    .standard-card__content-desc {
      .standard-card__content-desc-currency {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: $grey-40;
        @include media-breakpoint-down(sm){      
          font-size: toRem(14);
        }
      }

      .standard-card__content-desc-prices {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.75rem;

        .standard-card__content-desc-price {
          font-size: 1.5625rem;
          font-weight: 600;
          line-height: 27px;
          color: $white;
          overflow: hidden;
          white-space: nowrap;
          @include media-breakpoint-down(sm){      
            font-size: toRem(20);
          }
        }

        .standard-card__content-desc-token {
          font-size: 1rem;
          font-weight: 400;
          line-height: 24px;
          color: $grey-60;
        }
      }
    }
  }
}

.standard-card--type-3-v2 {
  position: relative;
  overflow-y: hidden;

  &::before {
    position: absolute;
    content: "";
    width: 4px;
    height: 100%;
  }

  &.btc::before {
    background-color: #f7931a;
  }

  &.eth::before {
    background-color: $purple;
  }

  &.usdt::before {
    background-color: #1ba27a;
  }

  &.bnb::before {
    background-color: #f7931a;
  }

  .standard-card__content {
    gap: 1.75rem;
  }

  .standard-card__content-head {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    .standard-card__content-desc {
      width: 100%;

      .standard-card__content-desc-prices {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }
    }

    .standard-card__content-desc-price {
      font-size: 1.5625rem;
      font-weight: 600;
      line-height: 27px;
      color: $white;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .standard-card__content-graph {
    align-items: flex-end !important;

    .standard-card__content-chart-wrapper {
      width: auto;
      height: auto;
      max-width: 178px;
      max-height: 48px;

      .standard-card__content-chart-render {
        position: relative;
      }
    }
  }
}

.standard-card--type-5-v1 {
  max-width: 435px;
  width: 100%;

  .container {
    padding: 0;
    padding-bottom: 0.5rem;
    position: relative;

    .standard-card__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.8125rem;

      padding: 1.5rem 1.5rem 0 1.5rem;

      .standard-card__title {
        font-family: $font-family-base;
        font-size: $h6-font-size;
        font-weight: $font-weight-bold;
        line-height: $h6-line-height;

        @include media-breakpoint-down(xs-2) {
          font-size: 1.125rem;
          line-height: 21.6px;
        }
      }

      .dropdown {
        .dropdown-toggle {
          &--card-type-5 {
            padding: 0.5rem 1rem;
            background-color: transparent;
          }
        }
      }
    }

    .standard-card__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      padding: 0 1.5rem 1.5rem 1.5rem;
      margin-right: 0.5rem;

      max-height: 182px;
      overflow: auto;

      @include media-breakpoint-down(xs-2) {
        padding: 0 0.75rem 1.5rem 1.5rem;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $grey-80;
        border-radius: 1.25rem;
      }

      .standard-card__content__list {
        display: flex;
        align-items: center;
        gap: 1rem;

        .standard-card__content__list-icon {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background-color: #252d55;

          display: flex;
          justify-content: center;
          align-items: center;

          @include media-breakpoint-down(xs-2) {
            width: 2rem;
            height: 2rem;
          }

          @include media-breakpoint-down(xs-1) {
            display: none;
          }

          .standard-card__content__list-icon-image {
            width: auto;
            height: auto;
          }
        }

        .standard-card__content__list-info-left,
        .standard-card__content__list-info-right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.125rem;
        }

        .standard-card__content__list-info-left {
          .standard-card__content__list-info-date {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: $line-height-sm;
            color: $white;

            @include media-breakpoint-down(xs-2) {
              font-size: 0.875rem;
              line-height: 1.5rem;
            }
          }

          .standard-card__content__list-info-status {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: $line-height-sm;
            color: $grey-60;

            @include media-breakpoint-down(xs-2) {
              font-size: 0.875rem;
              line-height: 1.5rem;
            }
          }
        }

        .standard-card__content__list-info-right {
          align-items: flex-end;
          margin-left: auto;

          .standard-card__content__list-info-price {
            font-size: $font-size-base;
            font-weight: 600;
            line-height: $line-height-sm;
            color: $white;

            @include media-breakpoint-down(xs-2) {
              font-size: 0.875rem;
              line-height: 1.5rem;
            }

            .standard-card__content__list-info-price-value {
              color: $green;
            }
          }

          .standard-card__content__list-info-id {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: $line-height-sm;
            color: $grey-60;

            @include media-breakpoint-down(xs-2) {
              font-size: 0.875rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }

    .standard-card__vignette {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 66px;
      background: linear-gradient(
        360deg,
        #1d1f25 7.73%,
        rgba(33, 36, 50, 0) 79.97%
      );
      border-radius: 0.75rem;
      pointer-events: none;
    }
  }
}

.standard-card--type-5-v2 {
  max-width: 435px;
  width: 100%;

  .container {
    padding: 0;
    padding-bottom: 0.5rem;
    position: relative;
    max-width: 100%;

    .standard-card__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.8125rem;

      padding: 1.5rem 1.5rem 0 1.5rem;

      .standard-card__title {
        font-family: $font-family-base;
        font-size: $h6-font-size;
        font-weight: $font-weight-bold;
        line-height: $h6-line-height;

        @include media-breakpoint-down(xs-2) {
          font-size: 1.125rem;
          line-height: 21.6px;
        }
      }

      .dropdown {
        .dropdown-toggle {
          &--card-type-5 {
            padding: 0.5rem 1rem;
            background-color: transparent;
          }
        }
      }
    }

    .standard-card__content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      padding: 0 1.5rem 1.5rem 1.5rem;
      margin-right: 0.5rem;

      max-height: 182px;
      overflow: auto;

      @include media-breakpoint-down(xs-2) {
        padding: 0 0.75rem 1.5rem 1.5rem;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $grey-80;
        border-radius: 1.25rem;
      }

      .standard-card__content__list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .standard-card__content__list-currency {
          display: flex;
          align-items: center;
          gap: 1rem;

          @include media-breakpoint-down(xs-1) {
            gap: 0.5rem;
          }

          .standard-card__content__list-currency-icon {
            width: auto;
            height: auto;
            max-width: 2rem;
            max-height: 2rem;

            @include media-breakpoint-down(xs-2) {
              max-width: 1.5rem;
              max-height: 1.5rem;
            }
          }

          .standard-card__content__list-currency-name {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: $line-height-sm;
            color: $white;

            @include media-breakpoint-down(xs-2) {
              font-size: 0.875rem;
            }
          }
        }

        .standard-card__content__list-status {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 0.5rem;

          max-width: 90.56px;
          width: 100%;

          @include media-breakpoint-down(xs-1) {
            gap: 0.125rem;
          }

          .standard-card__content__list-status-name {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: $line-height-sm;
            color: $grey-60;

            @include media-breakpoint-down(xs-2) {
              font-size: 0.875rem;
            }
          }

          .standard-card__content__list-status-icon {
            width: auto;
            height: auto;
            max-width: 1.5rem;
            max-height: 1.5rem;

            @include media-breakpoint-down(xs-2) {
              max-width: 1.25rem;
              max-height: 1.25rem;
            }
          }
        }

        .standard-card__content__list-price-value {
          font-size: $font-size-base;
          font-weight: 600;
          line-height: $line-height-sm;
          color: $white;

          @include media-breakpoint-down(xs-2) {
            font-size: 0.875rem;
          }
        }
      }
    }

    .standard-card__vignette {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 66px;
      background: linear-gradient(
        360deg,
        #1d1f25 7.73%,
        rgba(33, 36, 50, 0) 79.97%
      );
      border-radius: 0.75rem;
      pointer-events: none;
    }
  }
}

.standard-card--type-6-v1,
.standard-card--type-6-v2,
.standard-card--type-6-v3 {
  position: relative;
  background-clip: padding-box;
  border: 2px solid transparent;
  background-color: $darker-grey;

  width: 320px;

  @include media-breakpoint-down(xs-1) {
    width: auto;
    max-width: 300px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: $royal-blue-gradient;
  }

  .container {
    padding: 1.5rem;
    @include media-breakpoint-down(xs-1) {
      padding: 1rem;
    }

    .standard-card__content-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 1rem;

      @include media-breakpoint-down(md) {
        margin-bottom: 0.5rem;
      }

      .standard-card__content-currency {
        display: flex;
        align-items: center;
        gap: 1rem;

        .standard-card__content-currency-icon {
          width: auto;
          height: auto;
          max-width: 1.5rem;
          max-height: 1.5rem;
        }

        .standard-card__content-currency-name {
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 1.6875rem;
          color: $white;

          @include media-breakpoint-down(md) {
            font-size: 1rem;
          }
        }
      }

      .standard-card__content-percentage {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.6875rem;

        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }

        &.text-bullish {
          color: $green;
        }
        &.text-bearish {
          color: $red;
        }
      }
    }

    .standard-card__content-price-1 {
      font-size: 1.5625rem;
      font-weight: 600;
      line-height: 1.6875rem;
      color: $white;
      margin-bottom: 0.5rem;

      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
      }
    }

    .standard-card__content-price-2 {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: $grey-40;

      @include media-breakpoint-down(md) {
        font-size: toRem(14);
      }
    }

    .standard-card__content-chart-wrapper {
      margin: 1.1875rem auto 0 auto;
      width: auto;
      height: auto;
      max-height: 72px;
      pointer-events: none;

      @include media-breakpoint-down(md) {
        margin-top: toRem(12);
      }

      .standard-card__content-chart-render {
        position: relative;
        transform: scale(1.1);
        right: 0.5rem;
        bottom: 1.125rem;
      }
    }
  }
}

.standard-card--type-6-v2 {
  background-color: $grey-100;
  &::before {
    content: unset;
  }
}

.standard-card--type-6-v3 {
  background-color: transparent;
  border: 2px dashed $grey-80;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &::before {
    content: unset;
  }

  .standard-card__content {
    display: flex;
    justify-content: center;

    .standard-card__content-button-wrapper {
      background: none;
      border: none;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      &:hover, &:focus, &:active, &:focus-visible, &:focus-within {
        .standard-card__content-button {
          transform: scale(1.1) rotate(180deg);
        }

        .standard-card__content-button-text {
          color: $grey-20;
        }
      }
  
      .standard-card__content-button {
        width: 93px;
        height: 93px;
        border-radius: 50%;
        border: 1px solid $grey-80;
        position: relative;
        transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  
        .standard-card__content-button-icon {
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
        }
        .standard-card__content-button-ellipse--inner {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: $grey-80;
  
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
        }
        .standard-card__content-button-ellipse--mid {
          width: 61px;
          height: 61px;
          border-radius: 50%;
          background: $dark-transparent;
  
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
        }
      }
  
      .standard-card__content-button-text {
        font-family: $font-family-base;
        color: $grey-40;
        transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
      }
    }
  }

}

.standard-card--type-7 {
  max-width: 435px;
  width: 100%;

  .container {
    padding: 0;
    padding-bottom: 0.5rem;
    position: relative;

    .standard-card__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      padding: 1.5rem 1.5rem 0 1.5rem;

      .standard-card__title {
        font-family: $font-family-base;
        font-size: $h6-font-size;
        font-weight: $font-weight-bold;
        line-height: $h6-line-height;

        @include media-breakpoint-down(xs-2) {
          font-size: 1.125rem;
          line-height: 21.6px;
        }
      }

      .dropdown {
        .dropdown-toggle {
          &--card-type-5 {
            padding: 0.5rem 1rem;
            background-color: transparent;
          }
        }
      }
    }

    .standard-card__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      padding: 0 1.5rem 1.5rem 1.5rem;
      margin-right: 0.5rem;

      max-height: 231px;
      overflow: auto;

      @include media-breakpoint-down(xs-2) {
        padding: 0 0.75rem 1.5rem 1.5rem;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $grey-80;
        border-radius: 1.25rem;
      }

      .standard-card__content__list {
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        .standard-card__content__list-icon {
          .standard-card__content__list-icon-image {
            width: auto;
            height: auto;
            max-width: 1.5rem;
            max-height: 1.5rem;
          }
        }

        .standard-card__content__list-info-left,
        .standard-card__content__list-info-right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.125rem;
        }

        .standard-card__content__list-info-left {
          .standard-card__content__list-info-crypto {
            color: $grey-60;
          }
        }

        .standard-card__content__list-info-right {
          align-items: flex-end;
          margin-left: auto;
          gap: 0.375rem;

          .standard-card__content__list-info-chart {
            width: auto;
            height: auto;
            max-width: 118px;
            max-height: 34px;

            .standard-card__content__list-info-chart-render {
              position: relative;
              top: -2rem;
              right: -0.5rem;
            }
          }

          .standard-card__content__list-info-infusion {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            .standard-card__content__list-info-infusion-value {
              font-weight: 500;
              &.text-bullish {
                color: $green;
              }
              &.text-bearish {
                color: $red;
              }
            }
          }
        }
      }
    }

    .standard-card__vignette {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 66px;
      background: linear-gradient(
        360deg,
        #1d1f25 7.73%,
        rgba(33, 36, 50, 0) 79.97%
      );
      border-radius: 0.75rem;
      pointer-events: none;
    }
  }
}

.standard-card--type-8-v1,
.standard-card--type-8-v2 {
  width: unset !important;

  @include media-breakpoint-down(lg) {
    overflow-x: auto;
    overflow-y: hidden;
    background: none;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: none;
  }

  .standard-card__container {
    padding: 1rem 1.5rem;

    @include media-breakpoint-down(lg) {
      padding: 0;
      max-width: 100%;
    }

    .standard-card__items {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      @include media-breakpoint-down(lg) {
        gap: 1rem;
        min-width: 960px;
        flex-wrap: wrap;
      }
      
      .standard-card__item {
        padding: 0.5rem 0;
        
        @include media-breakpoint-down(lg) {
          padding: 1.5rem;
          background: $grey-100;
          border-radius: 0.75rem;
          flex: 1 1 0px;
          align-self: stretch;
        }

        .standard-card__item-title {
          color: $grey-60;
          margin-bottom: 0.5rem;
        }
        
        &--1 {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 1.5rem;
          height: auto;
          transition: all linear 0.2s;
          
          &.collapsed {
            .standard-card__item-left {
              .standard-card__item-arrow {
                transform: rotate(180deg);
              }
            }

            .standard-card__item-collapse {
              display: block;
            }
          }

          .standard-card__item-left {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            width: 100%;
            margin: auto;

            .standard-card__item-image {
              width: auto;
              height: auto;
              max-width: 2.75rem;
              max-height: 2.75rem;
            }

            .standard-card__item-arrow {
              width: 1.5rem;
              height: 1.5rem;
              cursor: pointer;
              margin-left: auto;
              transform: rotate(0deg);
              transition: all linear .2s;
            }
          }

          .standard-card__item-collapse {
            width: 100%;
            border-top: 1px solid $grey-80;
            padding-top: 1.5rem;
            display: none;

            .item-collapse__group-1 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 1rem;

              .item-collapse__title {
                .item-collapse__title-text {
                  color: $grey-60;
                }

                .item-collapse__title-influsion {
                  display: flex;
                  align-items: center;
                  gap: 0.25rem;

                  .item-collapse__title-influsion-icon {
                    width: 1.25rem;
                    height: 1.25rem;
                  }

                  .item-collapse__title-influsion-value {
                    &.text-bullish {color: $green;}
                    &.text-bearish {color: $red;}
                  }
                }
              }

              .item-collapse__chart-wrapper {
                width: 150px;
                height: 41.67px;

                .item-collapse__chart-render {
                  position: relative;
                  top: -1.875rem;
                  right: -0.5rem;
                }
              }
            }

            .item-collapse__group-2 {
              display: flex;
              gap: 1.25rem;

              @include media-breakpoint-down(xs-1) {
                flex-wrap: wrap;
                text-align: center;
                gap: 0.5rem;
              }

              .item-collapse__price {
                flex: 1 1 0px;
                white-space: nowrap;

                .item-collapse__price-value {
                  &.text-bullish {color: $green;}
                  &.text-bearish {color: $red;}
                }
              }
            }
          }
        }

        &--2 {
          display: flex;
          align-items: center;
          gap: 1rem;

          .standard-card__item-left {
            .standard-card__item-influsion {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              .standard-card__item-influsion-icon {
                width: 1.5rem;
                height: 1.5rem;
              }

              .standard-card__item-influsion-value {
                &.text-bullish {
                  color: $green;
                }
                &.text-bearish {
                  color: $red;
                }
              }
            }
          }

          .standard-card__item-chart-wrapper {
            max-width: 86px;
            max-height: 28px;

            .standard-card__item-chart-render {
              position: relative;
              top: -2.25rem;
              right: -0.5rem;
            }
          }
        }

        &--3,
        &--4 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .standard-card__separator {
        width: 1px;
        background: $darker-grey;
        align-self: stretch;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

}

.standard-card--type-8-v2 {
  background: none;

  .standard-card__container {
    padding: 0;

    .standard-card__items {
      gap: 1rem;
      align-items: flex-start;

      .standard-card__item {
        padding: 1.5rem;
        background: $grey-100;
        border-radius: 0.75rem;

        &--3,
        &--4 {
          display: flex;
          flex-direction: column;
          justify-content: center !important;
        }
      }

    }
  }
}