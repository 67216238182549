.component__content--advance-card {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.advance-card {
  min-width: 343px;
  max-width: 343px;
  background-color: $grey-100;
  padding: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(xs-2) {
    min-width: unset;
    max-width: 320px;
  }

  .advance-card__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @include media-breakpoint-down(xs-2) {
      margin-bottom: 1.5rem;
    }
  }

  .advance-card__price {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-family: $font-family-secondary;

    @include media-breakpoint-down(xs-2) {
      margin-bottom: 1rem;
    }

    .advance-card__price-crypto {
      display: flex;
      align-items: center;
      gap: 0.5625rem;

      @include media-breakpoint-down(xs-2) {
        gap: 0.5rem;
      }

      .advance-card__price-crypto-value {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.6875rem;
        color: $white;

        @include media-breakpoint-down(xs-2) {
          font-size: 1.125rem;
        }
      }

      .advance-card__price-crypto-name {
        font-size: 1rem;
        font-weight: 400;
        color: $grey-40;

        @include media-breakpoint-down(xs-2) {
          font-size: 0.875rem;
        }
      }
    }

    .advance-card__price-arrow {
      margin: 0 1rem;
    }

    .advance-card__price-currency {
      display: flex;
      align-items: center;
      gap: 0.875rem;

      @include media-breakpoint-down(xs-2) {
        gap: 0.5rem;
      }

      .advance-card__price-currency-value {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.6875rem;
        color: $white;

        @include media-breakpoint-down(xs-2) {
          font-size: 1.125rem;
        }
      }

      .advance-card__price-currency-name {
        font-size: 1rem;
        font-weight: 400;
        color: $grey-40;

        @include media-breakpoint-down(xs-2) {
          font-size: 0.875rem;
        }
      }
    }
  }

  .advance-card__forms-currency {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: toRem(20);

    .advance-card__forms-currency__title {
      font-family: $font-family-secondary;
      font-size: 1rem;
      font-weight: 400;
      color: $grey-60;
      margin-bottom: 0.5rem;
    }

    .forms-currency--advance-card {
      .forms-group--currency {
        width: unset !important;
        .forms-group__dropdown {
          row-gap: 1rem;
        }
      }
    }

    .forms-purchase--advance-card {
      background: none !important;

      .forms-group--purchase {

        .forms-purchase__purchase {
          width: auto;
        }
      }
    }
  }

  .advance-card__button {
    position: relative;
    margin-top: auto;
    width: 100%;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      padding: 1rem 0;
    }
  }
}

.advance-card--type-2 {

  .advance-card__title {
    margin-bottom: 1.5rem;

    .advance-card__title-image {
      width: auto;
      height: auto;
      max-width: 2rem;
      max-height: 2rem;
    }
  }

  .advance-card__form {
    margin-bottom: 1rem;

    .advance-card__form-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 0.5rem;

      .advance-card__form-title-text {
        font-family: $font-family-secondary;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: $grey-60;
      }

      .advance-card__form-title-percent {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        .advance-card__form-title-percent-value {
          font-family: $font-family-secondary;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.5rem;

          &.text-bullish {
            color: $green;
          }
          &.text-bearish {
            color: $red;
          }
        }
      }
    }

    .forms-purchase--advance-card {
      background: none !important;

      .forms-group--purchase {
        padding-right: 0;
        width: unset !important;
        
        .forms-group__dropdown {
          left: unset;
          right: 0;
        }
      }
    }
  }

  .advance-card__payment {

    .advance-card__payment-title {
      margin-bottom: 1rem;

      .advance-card__payment-title-text {
        font-family: $font-family-secondary;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: $grey-60;
      }
    }
  }

  .advance-card__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .advance-card__button {
      width: 100%;
      background: $grey-100;
      margin-top: 2.75rem;

      &:hover {
        background: $darker-grey; 
      }

      &.button--green {
        border: 1px solid $green !important;
        color: $green;
      }
      &.button--red {
        border: 1px solid $red !important;
        color: $red;
      }
    }
  }
}

.advance-card--type-3-v1,
.advance-card--type-3-v2 {

  .advance-card__title {
    justify-content: flex-start;
    gap: 1rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }

    .advance-card__title-image {
      width: auto;
      height: auto;
      max-width: 2rem;
      max-height: 2rem;

      @include media-breakpoint-down(md) {
        max-width: 1.75rem;
        max-height: 1.75rem;
      }
    }

    .advance-card__title-text {
      font-family: $font-family-secondary;
      font-weight: 600;

      @include media-breakpoint-down(md) {
        font-size: 1.125rem;
      }
    }
  }

  .advance-card__balance {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;
    font-family: $font-family-secondary;

    @include media-breakpoint-down(md) {
      gap: 0.25rem;
      margin-bottom: 1.5rem;
    }

    .advance-card__balance-title {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: $grey-40;

      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
      }
    }

    .advance-card__balance-crypto-value {
      font-size: 1.5625rem;
      font-weight: 600;
      line-height: 1.6875rem;
      color: $white;

      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
      }
    }

    .advance-card__balance-currency-value {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.6875rem;
      color: $grey-40;

      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }
  }

  .advance-card__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-bottom: 1.5rem;
    max-height: 99px;
    overflow: hidden;
    font-family: $font-family-secondary;

    background: rgba(47, 50, 65, 0.5);
    border-radius: 0.75rem;

    @include media-breakpoint-down(md) {
      background: none;
      padding: 1rem;
      border: 1px solid $grey-80;
      margin-bottom: 1rem;
    }

    .advance-card__card-desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.5rem;

      @include media-breakpoint-down(md) {
        gap: 0.25rem;
      }

      .advance-card__card-desc-title {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: $grey-60;
        white-space: nowrap;

        @include media-breakpoint-down(md) {
          font-size: 0.875rem;
        }
      }

      .advance-card__card-desc-value {

        .advance-card__card-desc-value-crypto {
          font-size: 1.125rem;
          font-weight: 600;
          line-height: 1.6875rem;
          color: $white;

          @include media-breakpoint-down(md) {
            font-size: 1rem;
          }
        }

        .advance-card__card-desc-value-currency {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          color: $green;

          @include media-breakpoint-down(md) {
            font-size: 0.875rem;
          }
        }
      }
    }

    .advance-card__card-chart-wrapper {
      position: relative;
      max-width: 90px;
      top: 0.75rem;

      .advance-card__card-chart-render {
        position: relative;
      }
    }
  }

  .advance-card__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;

    .advance-card__button {
      width: 100%;
      min-width: 47px;
      margin-top: 0.5rem;

      &.btn-secondary {
        background: $grey-100;

        &:hover {
          background: $darker-grey; 
        }
      }
    }
  }
}

.advance-card--type-3-v2 {

  .advance-card__card {
    flex-direction: column;
    max-height: fit-content;
    gap: 0.875rem;
    background: none;
    padding: 0;
    margin-bottom: 1.5rem;
    border-radius: 0;

    @include media-breakpoint-down(md) {
      padding: 1rem;
      border: 1px solid $grey-80;
      border-radius: 0.75rem;
      margin-bottom: 1rem;
      gap: 0.5rem;
    }

    .advance-card__card-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.875rem;

      .advance-card__card-data {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.25rem;
  
        .advance-card__card-chart-wrapper {
          max-height: 21px;
  
          .advance-card__card-chart-render {
            position: relative;
            top: -2.8125rem;
            right: 1.5rem;
          }
        }
  
        .advance-card__card-percent {
          font-family: $font-family-secondary;
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 19.2px;
          color: $white;
        }
      }
    }

    .advance-card__card-progress {
      width: 100%;
      height: 0.5rem;
      background-color: $darker-grey;
      border-radius: 3.125rem;

      .advance-card__card-progress-bar {
        height: 100%;
        border-radius: 3.125rem;
        width: 60%;

        &.progress-bar--purple {
          background-color: $purple;
        }

        &.progress-bar--green {
          background-color: $green-light;
        }
      }
    }
  }

  .advance-card__buttons {

    .advance-card__button {
      margin-top: 0.75rem;

      @include media-breakpoint-down(md) {
        margin-top: 0.5rem;
      }
    }
  }
}

.advance-card-wrapper:has(.advance-card--type-3-v3),
.advance-card-wrapper:has(.advance-card--type-3-v4) {
  width: 100%;
}

.advance-card--type-3-v3,
.advance-card--type-3-v4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 1008px;
  padding: 2rem;
  font-family: $font-family-secondary;

  @include media-breakpoint-down(lg-2) {
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
  }

  @include media-breakpoint-down(md) {
    max-width: 343px;
  }

  @include media-breakpoint-down(xs-2) {
    max-width: 320px;
  }

  .advance-card-left {
    width: 100%;
    max-width: 262px;

    @include media-breakpoint-down(md) {
      max-width: none;
    }

    .advance-card__title {
      justify-content: flex-start;
      gap: 1rem;

      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }

      .advance-card__title-image {
        width: auto;
        height: auto;
        max-width: 2rem;
        max-height: 2rem;

        @include media-breakpoint-down(md) {
          max-width: 1.75rem;
          max-height: 1.75rem;
        }
      }

      .advance-card__title-text {
        font-weight: 600;

        @include media-breakpoint-down(md) {
          font-size: 1.125rem;
        }
      }
    }

    .advance-card__balance-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .advance-card__balance {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
  
        @include media-breakpoint-down(md) {
          gap: 0.25rem;
        }
  
        .advance-card__balance-title {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          color: $grey-60;
  
          @include media-breakpoint-down(md) {
            font-size: 0.875rem;
          }
        }
  
        .advance-card__balance-crypto-value {
          font-family: $font-family-base;
          font-size: 2.4375rem;
          font-weight: 600;
          line-height: 120%;
          color: $white;
  
          @include media-breakpoint-down(md) {
            font-size: 1.25rem;
          }
        }
  
        .advance-card__balance-currency-value {
          font-size: 1.5625rem;
          font-weight: 400;
          line-height: 1.6875rem;
          color: $grey-40;
  
          @include media-breakpoint-down(md) {
            font-size: 1rem;
          }
        }
      }

      .advance-card__balance-chart {
        display: none;

        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 0.5rem;
        }

        .advance-card__balance-chart-wrapper {
          max-width: 85px;
          max-height: 21px;

          .advance-card__balance-chart-render {
            position: relative;
            top: -1.875rem;
            right: 1.5rem;
          }
        }

        .advance-card__balance-chart-percent {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          .advance-card__balance-chart-percent-image {
            width: auto;
            height: auto;
            max-width: 1rem;
            max-height: 1rem;
          }

          .advance-card__balance-chart-percent-value {
            font-family: $font-family-secondary;
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 1.5rem;

            &.text-bullish {
              color: $green;
            }
            &.text-bearish {
              color: $red;
            }
          }
        }
      }
    }

    .advance-card__buttons {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 2.75rem;

      @include media-breakpoint-down(md) {
        margin-top: 1.5rem;
      }

      .advance-card__button {
        margin: 0;
        
        &.btn-secondary {
          background: $grey-100;
          &:hover{
            background: $darker-grey;
          }
        }
      }
    }
  }

  .advance-card-separator {
    width: 1px;
    background: $grey-80;

    @include media-breakpoint-down(lg-2) {
      display: none;
    }
  }

  .advance-card-right {
    width: 100%;
    max-width: 517px;

    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 2.5rem;

    @include media-breakpoint-down(lg-2) {
      max-width: none;
      gap: 1rem;
    }

    .advance-card__card {
      @include media-breakpoint-down(md) {
        padding: 1rem;
        background: $dark-transparent;
        border-radius: 0.75rem;
      }

      .advance-card__card-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .advance-card__card-desc {
          font-family: $font-family-secondary;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          @include media-breakpoint-down(md) {
            gap: 0.25rem;
          }

          .advance-card__card-desc-title {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            color: $grey-60;

            @include media-breakpoint-down(md) {
              font-size: 0.875rem;
            }
          }

          .advance-card__card-desc-value {

            .advance-card__card-desc-value-crypto {
              font-size: 1.5625rem;
              font-weight: 600;
              line-height: 1.6875rem;
              color: $white;

              @include media-breakpoint-down(md) {
                font-size: 1rem;
              }
            }

            .advance-card__card-desc-value-currency {
              font-size: 1.125rem;
              font-weight: 400;
              line-height: 1.6875rem;
              color: $green;

              @include media-breakpoint-down(md) {
                font-size: 0.875rem;
              }
            }
          }
        }

        .advance-card__card-data {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          gap: 0.5rem;

          @include media-breakpoint-down(md) {
            gap: 0.25rem;
          }

          .advance-card__card-chart-wrapper {
            max-width: 156px;
            max-height: 40px;
            overflow: hidden;

            @include media-breakpoint-down(md) {
              max-width: 85px;
              max-height: 21px;
            }

            .advance-card__card-chart-render {
              position: relative;
              top: -1.875rem;
              right: 1.5rem;

              @include media-breakpoint-down(md) {
                max-width: 85px;
                max-height: 21px;
              }
            }
          }

          .advance-card__card-percent {
            font-family: $font-family-secondary;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.5rem;
            color: $white;

            @include media-breakpoint-down(md) {
              font-size: 0.75rem;
            }
          }
        }


      }

      .advance-card__card-progress {
        width: 100%;
        height: 0.8125rem;
        background-color: $darker-grey;
        border-radius: 3.125rem;
        margin-top: 1rem;

        @include media-breakpoint-down(md) {
          height: 0.5rem;
          margin-top: 0.5rem;
        }
  
        .advance-card__card-progress-bar {
          height: 100%;
          border-radius: 3.125rem;
          width: 75%;
          
          &.progress-bar--purple {
            background-color: $purple;
          }
  
          &.progress-bar--green {
            background-color: $green-light;
          }
        }
      }
    }
  }
}

.advance-card--type-3-v4 {

  .advance-card-right {
    max-width: 577px;

    @include media-breakpoint-down(lg-2) {
      max-width: none;
    }

    .advance-card__card {
      padding: 1.15625rem 2rem;
      border: 1px solid $grey-80;
      border-radius: 0.5rem;

      @include media-breakpoint-down(md) {
        padding: 1rem;
        border: none;
        background: $dark-transparent;
        border-radius: 0.75rem;
      }

      .advance-card__card-content {
        gap: 1.5rem;
        
        @include media-breakpoint-down(md) {
          justify-content: space-between;
        }

        .advance-card__card-data--line {
          @include media-breakpoint-down(md) {
            display: none;
          }

          .advance-card__card-chart-wrapper {
            max-width: 156px;
            max-height: 40px;

            .advance-card__card-chart-render {
              position: relative;
              top: -1.875rem;
              right: 1.5rem;
            }
          }
        }

        .advance-card__card-data--radial {

          .advance-card__card-chart-wrapper {
            max-width: 96px;
            max-height: 96px;

            .advance-card__card-chart-render {
              position: relative;
              top: -1.5rem;
              right: 2.375rem;

              @include media-breakpoint-down(md) {
                top: -1rem;
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}