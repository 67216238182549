.table{
  &--dark-transparent{
    background: $dark-transparent;
    backdrop-filter: blur(12px);
  }

  // Dashboard Table
  &-wrapper {
    background: $grey-100;
    border-radius: 0.75rem;
    position: relative;
    overflow: hidden;  
    .table-container {
      padding: 0 1.5rem;
      background: $grey-100;
      position: relative;
      overflow: auto;

      
      &::-webkit-scrollbar-track{
        background-color: transparent;
      }

      &::-webkit-scrollbar{
        height: toRem(4);
        width: toRem(4);
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb{
        border-radius: toRem(20);
        background-color: $grey-80;
      }
    }
  
    .table__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      padding: 1.5rem 1.5rem 0;
      
      @include media-breakpoint-down(md) {
        margin-bottom: 1.5rem;
      }
  
      .table__title-tabs {
        display: flex;
        align-items: center;
        gap: 1rem;
        list-style: none;
  
        @include media-breakpoint-down(lg) {
          display: none;
        }
  
        .table__title-tab {
          padding: 0.5rem 1rem;
          border-radius: 3.125rem;
          color: $grey-60;
          font-family: $font-family-base;
          line-height: 1.6;
          transition: all linear .2s;
          cursor: pointer;
  
          &:hover {
            color: $white;
          }
          
          &.active {
            background: $darker-grey;
            color: $white;
          }
        }
      }
  
      .table__dropdown {
        display: none;
  
        @include media-breakpoint-down(lg) {
          display: block;
        }
  
        .btn {
          padding: 0.5rem 1rem;
          background: none;
        }
      }
    }
  
    .table__vignette {
      position: absolute;
      height: 53px;
      left: 0; bottom: 0; right: 0;
      background: linear-gradient(0deg, #1D1F25 7.73%, rgba(29, 31, 37, 0) 79.97%);
      pointer-events: none;
      border-radius: inherit;
  
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
  
  &--type-1,
  &--type-2,
  &--type-3 {
    padding-top: 1.5rem;
    margin-bottom: 0;
    
    .table-container {
      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        background: none;
      }

      .table-content {
        margin-bottom: 0.625rem;
        min-width: 983px;
    
        thead {
          .table__head {
            padding: 0 2rem 1rem 0;
            color: $grey-60;
            border-bottom-color: $grey-80;
    
            &:last-child {
              padding-right: 0;
            }
          }
        }
    
        tbody {
          td {
            padding: 1rem 2rem 1rem 0;
            border: none;
            vertical-align: middle;
    
            &:first-child,
            &:last-child {
              padding-right: 0;
            }
    
            .table__assets-crypto {
              display: flex;
              align-items: center;
              gap: 1rem;
    
              .table__assets-crypto-icon {
                width: auto;
                height: auto;
                max-width: 1.5rem;
                max-height: 1.5rem;
              }
            }
    
            .table__assets-name {
              color: $grey-40;
            }
    
            .table__market {
              &.text-bullish {
                color: $green;
              }
              &.text-bearish {
                color: $red;
              }
            }
          }
        }
      }
    }
  }
  
  &--type-2 {
    padding-top: 0;
  
    .table-container {
      .table-content {
        margin: 0;
        min-width: 983px;

        tbody {
          td {
            &.table__chart {
              max-width: 96px;
    
              .table__chart-wrapper {
                width: auto;
                height: auto;
                max-width: 96px;
                max-height: 28px;
                overflow: hidden;
    
                .table__chart-render {
                  position: relative;
                  top: -1.875rem;
                  left: -1.25rem;
                }
              }
            }
    
            .table__btn {
              background: none;
              transition: all 0.2s linear;
            }
          }
        }
      }
    }
  }

  &--type-3 {
    padding-top: 0;

    .table-container {
      .table-content {
        margin: 0;
        // min-width: 983px;
        min-width: max-content;
        table-layout: fixed;
        @include media-breakpoint-down(md) {
          min-width: toRem(640);
        }
        thead{                
          position: sticky;
          top: 0;
          background: $grey-100;
          box-shadow: inset 0 -1px 0 $grey-80;
        }
        tbody {
          tr {
            td {
              width: 100%;
              padding: 0.5rem 2rem 0.5rem 0;

              .text-bullish {
                color: $green;
              }
              .text-bearish {
                color: $red;
              }
            }

            &:first-child {
              td {
                padding-top: 1rem;
              }
            }

            &:last-child {
              td {
                padding-bottom: 1rem;
              }
            }
          }
        }
      }
    }
  }
}